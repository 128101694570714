import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import ImageWithFallback from '../../Shared/ImageWithFallback';
import { getCdnUrl } from '../../../Services/ImageResizer';

type Props = {
  src: String,
  backgroundColor: String,
  alt: String,
  classes: Object,
  sizes: Number,
};

function OrganisationAvatar(props: Props) {
  const { src, backgroundColor, alt, classes, sizes } = props;

  return (
    <ImageWithFallback
      src={getCdnUrl(src, sizes || 60)}
      loadRenderer={
        <Avatar
          className={classes}
          style={{ backgroundColor, width: sizes, height: sizes }}
          alt={alt}
        >
          <CircularProgress />
        </Avatar>
      }
      successRenderer={
        <Avatar className={classes} src={getCdnUrl(src, sizes || 60)} alt={alt} sizes={sizes} />
      }
      errorRenderer={
        <Avatar className={classes} style={{ backgroundColor, width: sizes, height: sizes }}>
          {alt}
        </Avatar>
      }
    />
  );
}
export default OrganisationAvatar;
