import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Paper from '@material-ui/core/Paper';
import {
  ToiletteIcon,
  RepasIcon,
  MenageIcon,
  LitIcon,
  LingeIcon,
  RepassageIcon,
  CourseIcon,
  AccompagnementIcon,
  CityIcon,
} from '../../Themes/Icon';
import Footer from '../../Components/Layout/Footer';
import FooterUna from './Footer';
import FooterContentUna from './FooterContent';

import ServiceCard from '../../Components/Block/ServiceCard';
import CardOrganisationResult from '../../Components/Organisations/Shared/CardOrganisationResult';
import CardOrganisationResultMain from '../../Components/Organisations/Shared/CardOrganisationResultMain';
import OrganisationsLeagueActions from '../../Redux/OrganisationsLeagueRedux';
import CardTaskResult from '../../Components/Organisations/Shared/CardTaskResult';
import { isReferenced } from '../../Acl/Controls';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';

import { Images } from '../../Themes';
import Routes from '../../Routing/Routes';
import Seo from '../../Routing/Seo';
import { SERVICE_TYPES } from '../../Services/ServiceHelper';
import SearchBar from './SearchBar';
import { getCityName } from './UnaHelper';
import { isClientSide } from '../../Config/ServerConfig';

type Props = {
  classes: Object,
  match: Object,
  location: Object,
  t: Function,

  organisations: [],
  getOrganisationsLeague: Function,
};

const styles = theme => ({
  bold: {
    fontWeight: 600,
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    height: '200%',
    backgroundImage: `url(${Images.una.sub_jumbo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '4rem 0',
  },
  heroTitle: {
    color: 'white',
    textShadow: '0.5px 0.5px #3c3c3c73',
  },
  leftContent: {},
  rightContent: {
    marginTop: '0px',
    position: 'sticky',
    top: '4rem',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    alignItems: 'flex-start',
  },
  cityLayout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '5rem 3rem 3rem',
  },
  headingContainerSmall: {
    width: '100%',
    margin: '0rem 0 0rem',
  },
  subHeroLayout: {
    width: '100%',
    height: '250px',
    margin: '3rem 0 3rem',
    backgroundSize: 'cover',
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  leafLetContainer: {
    marginBottom: theme.spacing(2),
    height: '350px',
  },
  searchBar: {
    marginTop: 0,
  },
  root: {
    padding: theme.spacing(3, 2),
  },
  containerValue: {
    display: 'flex',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  imgValue: {
    maxWidth: '40px',
    maxHeight: '50px',
    verticalAlign: 'top',
  },
  contentValue: {
    marginLeft: '20px',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '0%',
  },
  card: {
    margin: '2em',
  },
  cardLogo: {
    width: '200px',
    height: '90px',
    marginBottom: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    margin: 0,
    padding: 0,
    color: '#737373',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  mainOrganisationContainer: {
    paddingBottom: 10,
  },
});

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i -= 1) {
    const lastnum = new Date().getMinutes().toString().slice(-1);
    const j = Math.floor((lastnum / 10) * (i + 1));
    // const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j]; // eslint-disable-line no-param-reassign
    array[j] = temp; // eslint-disable-line no-param-reassign
  }
  return array;
}

class SearchResult extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      citySlug: _.get(props, 'match.params.city_slug'),
      opened: false,
    };
    this.fetchData(); // for SSR (SEO)
    this.toggleBox = this.toggleBox.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    const {
      match: {
        params: { city_slug: citySlug },
      },
    } = this.props;
    const { citySlug: oldCitySlug } = this.state;

    if (citySlug !== oldCitySlug) {
      this.fetchData();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ citySlug, opened: false });
    }
  }

  getFilter = () => {
    const { location } = this.props;
    const queryParam = new URLSearchParams(location.search);
    return queryParam.get('filter');
  };

  getTasks() {
    const { organisations } = this.props;
    let tasks = [];
    if (organisations) {
      organisations.forEach(organisation => {
        _.map(organisation.sampled_tasks, (task, key) => {
          tasks.push({
            ...task,
            organisation,
            id: task.id || key, // mock
          });
        });
      });
      tasks = _.sortBy(tasks, task => task.start_date);
    }
    return tasks;
  }

  getOrganisations = () => {
    const { organisations, match } = this.props;
    const { city_slug: citySlug } = match.params;
    const isGlobal = !citySlug;
    if (!isGlobal || !organisations) {
      return organisations;
    }
    const blackList = [
      'Wello 2',
      'test',
      'maison',
      'Fourcadie',
      'nursi',
      'AGORA',
      'Association ETRE',
    ];
    return _.uniqBy(
      organisations.filter(orga => !blackList.includes(orga.name)),
      'id',
    );
  };

  getMainOrganisations = organisations => {
    if (!organisations) return organisations;
    return shuffleArray(organisations.filter(orga => !isReferenced(orga)));
  };

  getOtherOrganisations = organisations => {
    if (!organisations) return organisations;
    return organisations.filter(orga => isReferenced(orga));
  };

  getServiceName = () => {
    const { match } = this.props;
    const { service_slug: serviceSlug } = match.params;
    const service = _.find(SERVICE_TYPES, type => type.slug === serviceSlug);
    return _.get(service, 'searchLabel') || 'des services à la personne';
  };

  getServiceLabel = () => {
    const { match } = this.props;
    const { service_slug: serviceSlug } = match.params;
    const service = _.find(SERVICE_TYPES, type => type.slug === serviceSlug);
    return _.get(service, 'label') || 'Services à la personne';
  };

  toggleBox() {
    this.setState({
      opened: true,
    });
  }

  fetchData() {
    const { match, getOrganisationsLeague } = this.props;
    const { league_id: leagueId, city_slug: citySlug } = match.params;
    getOrganisationsLeague(leagueId || Routes.currentLeague().id, citySlug);
  }

  renderTaskMap = tasks => {
    if (!isClientSide()) return <></>;
    const { classes } = this.props;
    const markers = [];
    const formattedAddresses = [];

    _.forEach(tasks, task => {
      const latitude = _.get(task, 'coordinates.latitude');
      const longitude = _.get(task, 'coordinates.longitude');
      markers.push([latitude, longitude]);
      const formattedAddress = _.get(task, 'formatted_address');
      formattedAddresses.push(formattedAddress);
    });
    if (markers.length === 0) return <></>;
    // eslint-disable-next-line global-require
    const L = require('react-leaflet');
    const bounds = markers.length > 1 ? window.L.latLngBounds(markers) : null;
    return (
      <L.MapContainer
        bounds={bounds}
        scrollWheelZoom={false}
        center={markers[0]}
        zoom={markers.length > 1 ? null : 10}
        className={classes.leafLetContainer}
      >
        <L.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {markers.map((marker, index) => (
          <L.Marker position={marker} key={formattedAddresses[index]}>
            <L.Popup>{formattedAddresses[index]}</L.Popup>
          </L.Marker>
        ))}
      </L.MapContainer>
    );
  };

  render() {
    const { t, classes, match } = this.props;
    const { city_slug: citySlug, service_slug: serviceSlug } = match.params;
    let cityName = getCityName(citySlug);
    const serviceName = this.getServiceName();
    const serviceLabel = this.getServiceLabel();
    const organisations = this.getOrganisations();
    const tasks = this.getTasks();
    const allOrganisations = [
      ...this.getMainOrganisations(organisations),
      ...this.getOtherOrganisations(organisations),
    ];
    const { opened } = this.state;
    if (
      cityName === 'Paris 1 Er Arrondissement' ||
      cityName === 'Paris 2 E Arrondissement' ||
      cityName === 'Paris 3 E Arrondissement' ||
      cityName === 'Paris 4 E Arrondissement' ||
      cityName === 'Paris 5 E Arrondissement' ||
      cityName === 'Paris 6 E Arrondissement' ||
      cityName === 'Paris 7 E Arrondissement' ||
      cityName === 'Paris 8 E Arrondissement' ||
      cityName === 'Paris 9 E Arrondissement' ||
      cityName === 'Paris 10 E Arrondissement' ||
      cityName === 'Paris 11 E Arrondissement' ||
      cityName === 'Paris 12 E Arrondissement' ||
      cityName === 'Paris 13 E Arrondissement' ||
      cityName === 'Paris 14 E Arrondissement' ||
      cityName === 'Paris 15 E Arrondissement' ||
      cityName === 'Paris 16 E Arrondissement' ||
      cityName === 'Paris 17 E Arrondissement' ||
      cityName === 'Paris 18 E Arrondissement' ||
      cityName === 'Paris 19 E Arrondissement' ||
      cityName === 'Paris 20 E Arrondissement'
    ) {
      cityName = 'Paris';
    }
    if (
      cityName === 'Lyon 1 Er Arrondissement' ||
      cityName === 'Lyon 2 E Arrondissement' ||
      cityName === 'Lyon 3 E Arrondissement' ||
      cityName === 'Lyon 4 E Arrondissement' ||
      cityName === 'Lyon 5 E Arrondissement' ||
      cityName === 'Lyon 6 E Arrondissement' ||
      cityName === 'Lyon 7 E Arrondissement' ||
      cityName === 'Lyon 8 E Arrondissement' ||
      cityName === 'Lyon 9 E Arrondissement'
    ) {
      cityName = 'Lyon';
    }
    if (
      cityName === 'Marseille 1 Er Arrondissement' ||
      cityName === 'Marseille 2 E Arrondissement' ||
      cityName === 'Marseille 3 E Arrondissement' ||
      cityName === 'Marseille 4 E Arrondissement' ||
      cityName === 'Marseille 5 E Arrondissement' ||
      cityName === 'Marseille 6 E Arrondissement' ||
      cityName === 'Marseille 7 E Arrondissement' ||
      cityName === 'Marseille 8 E Arrondissement' ||
      cityName === 'Marseille 9 E Arrondissement' ||
      cityName === 'Marseille 10 E Arrondissement' ||
      cityName === 'Marseille 11 E Arrondissement' ||
      cityName === 'Marseille 12 E Arrondissement' ||
      cityName === 'Marseille 13 E Arrondissement' ||
      cityName === 'Marseille 14 E Arrondissement' ||
      cityName === 'Marseille 15 E Arrondissement' ||
      cityName === 'Marseille 16 E Arrondissement'
    ) {
      cityName = 'Marseille';
    }
    const imagePath = `/images/leagues/${Routes.currentNamespace().toLowerCase()}/city/${cityName}.jpg`;

    const organisationUNA = {
      id: 1010,
      name: `Nous sommes présents à ${cityName}`,
      description:
        'Nous vous aidons, nous vous accompagnons en vous proposant une réponse personnalisée et adaptée à vos besoins',
      slug: 'una-administratif',
      banner_url: '/images/leagues/una/besoin_aide_a_domicile.jpg',
      sampled_helpers: [],
      type: 'Organisations::Sap',
      holding: {
        id: 57,
        league_id: 1,
        name: 'UNA National',
        cover_url: '/images/leagues/una/logo_circle.jpg',
        slug: 'una-national',
      },
    };

    return (
      <>
        <Helmet>
          {Seo.title(
            `${t(`${Routes.currentNamespace()}.SEO_SEARCH_RESULT_PAGE.PREFIX`)} ${cityName} - ${t(
              `${Routes.currentNamespace()}.SEO_SEARCH_RESULT_PAGE.${serviceSlug}.TITLE`,
            )}`,
          )}
        </Helmet>
        {/* HERO CTA SECTION */}
        <Grid container className={classes.container}>
          <Grid item className={classes.heroLayout}>
            <Grid item align="center" className={classes.layout}>
              <Typography
                variant="h2"
                component="h1"
                align="center"
                className={classes.heroTitle}
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('UNA.SEARCH_RESULT_PAGE.MAINTITLE', {
                    cityName,
                    serviceName,
                    serviceLabel,
                  }),
                }}
              />
              <SearchBar city={citySlug} service={serviceSlug} className={classes.searchBar} />
            </Grid>
          </Grid>
        </Grid>

        {/* RESULT ORGANISATION SECTION */}
        <Grid container className={classes.layout} spacing={4}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                component="h2"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('UNA.SEARCH_RESULT_PAGE.TITLE', {
                    cityName,
                    serviceName,
                    serviceLabel,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="div"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t(
                    `${Routes.currentNamespace()}.SEARCH_RESULT_PAGE.${serviceSlug}.SUBTITLE`,
                    { cityName, serviceName },
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container itemScope itemType="http://schema.org/ItemList" spacing={4} align="left">
            <Grid item md={8} xs={12} className={classes.leftContent}>
              <Grid container>
                {(allOrganisations || []).length === 0 && (
                  <Grid md={12} xs={12} className={classes.mainOrganisationContainer}>
                    <CardOrganisationResultMain
                      key={organisationUNA.id}
                      organisation={organisationUNA}
                      bannerUrl={Images.una.team}
                      name={organisationUNA.name}
                      holdingName={organisationUNA.name}
                      description={organisationUNA.description}
                      city={citySlug}
                    />
                  </Grid>
                )}
                {(allOrganisations || []).length > 0 &&
                  opened === true &&
                  allOrganisations.map((organisation, index) => (
                    <Grid
                      key={organisation.id}
                      md={12}
                      xs={12}
                      className={classes.mainOrganisationContainer}
                    >
                      {index === 0 && !isReferenced(organisation) && (
                        <CardOrganisationResultMain
                          key={`organisation-${organisation.id}`}
                          organisation={organisation}
                          bannerUrl={organisation.banner_url}
                          avatarUrl={
                            _.get(organisation, 'holding.cover_url') || organisation.banner_url
                          }
                          name={organisation.name}
                          holdingName={_.get(organisation, 'holding.name') || ''}
                          description={
                            organisation.holding?.short_description?.replace(/<[^>]*>/g, '') || ''
                          }
                          isOnline
                          city={citySlug}
                        />
                      )}
                      {index === 0 && isReferenced(organisation) && (
                        <CardOrganisationResultMain
                          key={`organisation-${allOrganisations[0].id}`}
                          organisation={allOrganisations[0]}
                          bannerUrl={Images.una.team}
                          name={allOrganisations[0].name}
                          holdingName={allOrganisations[0].name}
                          description={organisationUNA.description}
                          city={citySlug}
                        />
                      )}
                      {index > 0 && !isReferenced(organisation) && (
                        <CardOrganisationResult
                          key={`organisation-${organisation.id}`}
                          organisation={organisation}
                          bannerUrl={organisation.banner_url}
                          avatarUrl={
                            _.get(organisation, 'holding.cover_url') || organisation.banner_url
                          }
                          name={organisation.name}
                          holdingName={_.get(organisation, 'holding.name') || ''}
                          description={
                            organisation.holding?.short_description?.replace(/<[^>]*>/g, '') || ''
                          }
                          isOnline
                        />
                      )}
                      {index > 0 && isReferenced(organisation) && (
                        <CardOrganisationResult
                          key={`organisation-${organisation.id}`}
                          organisation={organisation}
                          bannerUrl={organisation.banner_url}
                          city={organisation.formatted_address}
                          name={organisation.name}
                          description={
                            organisation.holding?.short_description?.replace(/<[^>]*>/g, '') || ''
                          }
                        />
                      )}
                    </Grid>
                  ))}
                {(allOrganisations || []).length > 0 &&
                  opened === false &&
                  !isReferenced(allOrganisations[0]) && (
                    <Grid md={12} xs={12} item>
                      <CardOrganisationResultMain
                        key={`organisation-${allOrganisations[0].id}`}
                        organisation={allOrganisations[0]}
                        bannerUrl={allOrganisations[0].banner_url}
                        avatarUrl={
                          _.get(allOrganisations[0], 'holding.cover_url') ||
                          allOrganisations[0].banner_url
                        }
                        name={allOrganisations[0].name}
                        holdingName={_.get(allOrganisations[0], 'holding.name') || ''}
                        description={allOrganisations[0].description}
                        isOnline
                        city={citySlug}
                      />
                    </Grid>
                  )}
                {(allOrganisations || []).length > 0 &&
                  opened === false &&
                  isReferenced(allOrganisations[0]) && (
                    <Grid md={12} xs={12} item>
                      <CardOrganisationResultMain
                        key={`organisation-${allOrganisations[0].id}`}
                        organisation={allOrganisations[0]}
                        bannerUrl={Images.una.team}
                        name={allOrganisations[0].name}
                        holdingName={_.get(allOrganisations[0], 'holding.name') || ''}
                        description={organisationUNA.description}
                        city={citySlug}
                      />
                    </Grid>
                  )}
                {(allOrganisations || []).length > 0 && opened === false && (
                  <Typography gutterBottom className={classes.show_all}>
                    <button
                      type="button"
                      className={classes.linkButton}
                      onClick={this.toggleBox}
                      dangerouslySetInnerHTML={{ __html: t('UNA.SEARCH_RESULT_PAGE.SHOW_ALL') }}
                    />
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} className={classes.rightContent}>
              <Paper className={classes.root} elevation={0}>
                <Grid className={classes.containerValue}>
                  <img className={classes.imgValue} src={Images.una.benefit_card_1} alt="Wello" />
                  <Grid className={classes.contentValue}>
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      dangerouslySetInnerHTML={{ __html: t('UNA.HOMEPAGE.BENEFIT.VALUE_1.TITLE') }}
                    />
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: t('UNA.HOMEPAGE.BENEFIT.VALUE_1.CONTENT2'),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.containerValue}>
                  <img className={classes.imgValue} src={Images.una.benefit_card_2} alt="Wello" />
                  <Grid className={classes.contentValue}>
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      dangerouslySetInnerHTML={{ __html: t('UNA.HOMEPAGE.BENEFIT.VALUE_2.TITLE') }}
                    />
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: t('UNA.HOMEPAGE.BENEFIT.VALUE_2.CONTENT2'),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.containerValue}>
                  <img className={classes.imgValue} src={Images.una.benefit_card_3} alt="Wello" />
                  <Grid className={classes.contentValue}>
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      dangerouslySetInnerHTML={{ __html: t('UNA.HOMEPAGE.BENEFIT.VALUE_3.TITLE') }}
                    />
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: t('UNA.HOMEPAGE.BENEFIT.VALUE_3.CONTENT2'),
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {/* DIVIDER SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer} />
        </Grid>

        {/* SERVICE SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                component="h2"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('UNA.SEARCH_RESULT_PAGE.SERVICE.TITLE', {
                    cityName,
                    serviceName,
                    serviceLabel,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="div"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('UNA.SEARCH_RESULT_PAGE.SERVICE.SUBTITLE', {
                    cityName,
                    serviceName,
                    serviceLabel,
                  }),
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            {serviceSlug === 'aide-autonomie' && (
              <>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<LitIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE1`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME1`}
                    link="/nos-offres/aide-lever-coucher"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<ToiletteIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE2`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME2`}
                    link="/nos-offres/aide-a-la-toilette"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<RepasIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE3`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME3`}
                    link="/nos-offres/aide-au-repas"
                  />
                </Grid>
              </>
            )}
            {serviceSlug === 'aide-au-menage' && (
              <>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<MenageIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE1`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME1`}
                    link="/nos-offres/aide-au-menage"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<RepassageIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE2`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME2`}
                    link="/nos-offres/aide-au-menage"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<LingeIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE3`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME3`}
                    link="/nos-offres/aide-au-menage"
                  />
                </Grid>
              </>
            )}
            {serviceSlug === 'accompagnement-sortie' && (
              <>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<CourseIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE1`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME1`}
                    link="/nos-offres/aide-aux-courses"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<AccompagnementIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE2`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME2`}
                    link="/nos-offres/accompagnement-et-sortie"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ServiceCard
                    icon={<CityIcon />}
                    title={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.TITLE3`}
                    resume={`UNA.SEARCH_RESULT_PAGE.${serviceSlug}.RESUME3`}
                    link="/nos-offres/accompagnement-et-sortie"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* CITY SECTION */}
        {(cityName === 'Nantes' ||
          cityName === 'Angers' ||
          cityName === 'Laval' ||
          cityName === 'Paris' ||
          cityName === 'Rennes' ||
          cityName === 'Marseille' ||
          cityName === 'Lyon') && (
          <Grid container className={classes.container}>
            <Grid
              item
              className={classes.subHeroLayout}
              style={{
                backgroundImage: `url(${imagePath})`,
              }}
            >
              <Grid item className={classes.cityLayout}>
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.heroTitle}
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: t('UNA.SEARCH_RESULT_PAGE.CTA.TITLE', { cityName }),
                  }}
                />
                <Typography
                  variant="subtitle1"
                  component="h3"
                  align="center"
                  className={classNames(classes.heroTitleLocation, classes.heroTitle)}
                  paragraph
                  dangerouslySetInnerHTML={{ __html: t('UNA.SEARCH_RESULT_PAGE.CTA.SUBTITLE') }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* RESULT ACTIVITE SECTION */}
        <Grid container className={classes.layout}>
          {tasks && tasks.length > 0 && (
            <>
              <Grid item className={classes.headingContainer}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    component="h2"
                    align="center"
                    className={classes.bold}
                    gutterBottom
                  >
                    {t('UNA.SEARCH_RESULT_PAGE.ACTIVITY.TITLE', { cityName, serviceName })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph variant="subtitle1" align="center" gutterBottom>
                    {t('UNA.SEARCH_RESULT_PAGE.ACTIVITY.SUBTITLE', { cityName })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} sm={12}>
                  <Grid container spacing={2}>
                    {tasks.map(task => (
                      <Grid item xs={12} key={`task-${task.start_date}`}>
                        <CardTaskResult task={task} organisationPreview readOnly isReferenced />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  {this.renderTaskMap(tasks)}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Divider
          style={{ borderColor: 'transparent', margin: '40px 0', backgroundColor: 'transparent' }}
        />
        {/* SEO FOOTER SECTION */}
        {serviceSlug && cityName === 'Nantes' && (
          <FooterContentUna
            cityName={cityName}
            serviceName={serviceName}
            serviceSlug={serviceSlug}
          />
        )}
        {serviceSlug && cityName === 'Angers' && (
          <FooterContentUna
            cityName={cityName}
            serviceName={serviceName}
            serviceSlug={serviceSlug}
          />
        )}
        {serviceSlug && cityName === 'Laval' && (
          <FooterContentUna
            cityName={cityName}
            serviceName={serviceName}
            serviceSlug={serviceSlug}
          />
        )}
        {serviceSlug && cityName === 'Paris' && (
          <FooterContentUna cityName="Paris" serviceName={serviceName} serviceSlug={serviceSlug} />
        )}
        {serviceSlug && cityName === 'Rennes' && (
          <FooterContentUna
            cityName={cityName}
            serviceName={serviceName}
            serviceSlug={serviceSlug}
          />
        )}
        {serviceSlug && cityName === 'Marseille' && (
          <FooterContentUna
            cityName={cityName}
            serviceName={serviceName}
            serviceSlug={serviceSlug}
          />
        )}
        {/* FOOTER SECTION */}
        <FooterUna />
        <Divider />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  organisations: state.organisationsLeague.organisations,
});

const mapDispatchToProps = dispatch => ({
  getOrganisationsLeague: (leagueId, citySlug) =>
    dispatch(OrganisationsLeagueActions.getLeagueOrganisationsRequest(leagueId, citySlug)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withTranslation()(SearchResult)));
