import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';

type Props = {
  classes: Object,
  cityName: Object,
  serviceName: Object,
  serviceSlug: Object,
};

const styles = theme => ({
  nobullets: {
    listStyleType: 'none',
  },
  footerLayout: {
    backgroundColor: grey[200],
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
});

function FooterContentUna({ classes, cityName, serviceSlug, serviceName }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classes.footerLayout}>
      <Grid container justify="space-around" spacing={3}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid item md={12}>
            <Typography
              variant="h4"
              component="h2"
              dangerouslySetInnerHTML={{
                __html: t('UNA.SEARCH_RESULT_PAGE.CONTENT.CHAP_1', { cityName, serviceName }),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: t(
                  `UNA.SEARCH_RESULT_PAGE.${serviceSlug}.${cityName.toLowerCase()}.CONTENT_1`,
                  {
                    cityName,
                    serviceName,
                  },
                ),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="h4"
              component="h2"
              dangerouslySetInnerHTML={{
                __html: t('UNA.SEARCH_RESULT_PAGE.CONTENT.CHAP_2', { cityName, serviceName }),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: t(
                  `UNA.SEARCH_RESULT_PAGE.${serviceSlug}.${cityName.toLowerCase()}.CONTENT_2`,
                  {
                    cityName,
                    serviceName,
                  },
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider light style={{ marginTop: '30px' }} />
    </div>
  );
}

export default withStyles(styles)(FooterContentUna);
