import React from 'react';
import { withTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import Routes from '../../../Routing/Routes';

import WelloAvatar from '../../Shared/WelloAvatar';
import images from '../../../Themes/Images';
import OrganisationAvatar from './Avatar';

const styles = theme => ({
  header: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  organisation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up(theme.breakpoints.values.lg + theme.spacing(4))]: {
      minHeight: '70px',
      marginBottom: 0,
    },
  },
  actions: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
  },
  helpers: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    // '&:first-child': {
    //   marginLeft: 0,
    // },
  },
  avatar: {
    // marginLeft: '-24px',
    // display: 'inline-block',
    width: 50,
    height: 50,
    fontSize: '14px',
    border: '3px solid #fff',
  },
  button: {
    margin: 3,
  },
  card: {
    marginTop: '0px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '10px',
  },
  selectedCard: {
    marginTop: '0px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '10px',
    boxShadow: '0px 0px 10px 1px',
  },
});

type Props = {
  organisation: Number,
  avatarUrl: String,
  holdingName: String,
  name: String,
  classes: Object,
  isOnline: Object,
  onClick: Function,
  isSelected: Boolean,
  t: Function,
};

class CardOrganisation extends React.PureComponent<Props> {
  renderOrganisation = () => {
    const {
      classes,
      avatarUrl,
      name,
      holdingName,
      isOnline,
      organisation,
      onClick,
      isSelected,
      t,
    } = this.props;

    const fakeHelper = [];
    // eslint-disable-next-line no-plusplus
    for (let i = organisation.sampled_helpers.length; i < 5; i++) {
      fakeHelper.push({});
    }
    const avatarMore = organisation.helper_count - 5;
    return (
      <Card
        elevation={0}
        className={isSelected ? classes.selectedCard : classes.card}
        onClick={onClick}
      >
        {/* <CardMedia component="img" className={classes.media} height="140" src={bannerUrl} /> */}
        {/* <meta itemProp="image" content={bannerUrl} /> */}
        <CardContent style={{ textAlign: 'left', padding: '15px' }}>
          <Grid container>
            <Grid item xs={12} md={12} className={classes.organisation}>
              {/* ORGANISATION */}
              <List className={classes.header}>
                <ListItem alignItems="flex-start" className={classes.header}>
                  <ListItemAvatar>
                    <OrganisationAvatar
                      src={avatarUrl}
                      alt={organisation.name.charAt(0).toUpperCase()}
                      backgroundColor={organisation.banner_background_color}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6" component="h3">
                        {holdingName ? `${holdingName} - ${name}` : `${name}`}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2" component="div">
                        {organisation.formatted_address}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          {/* PRO */}
          <Grid container>
            <Grid item md={6} xs={12}>
              {organisation.sampled_helpers.length > 0 && (
                <div className={classes.helpers}>
                  {/* HELPERS */}
                  {organisation.sampled_helpers.map(helper => (
                    <WelloAvatar
                      avatarUrl={helper.avatar_url}
                      firstName={helper.first_name}
                      lastName={helper.last_name}
                      backgroundColor={helper.avatar_background_color}
                      avatarClass={classes.avatar}
                      size={50}
                    />
                  ))}
                  {fakeHelper.map(() => (
                    <Avatar className={classes.avatar} src={images.logo.wello_circle} />
                  ))}
                  {avatarMore > 0 && <Avatar className={classes.avatar}>+{avatarMore}</Avatar>}
                </div>
              )}
            </Grid>
            <Grid item md={6} xs={12} css={{ height: '100%' }}>
              {isOnline && (
                <Box height="100%" display="flex" alignItems="flex-end" flexDirection="row-reverse">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    href={`/${organisation.holding_slug}/${organisation.slug}/quotation`}
                    gutterBottom
                  >
                    {t('APP.ORGANISATION.HOME.SERVICES.ACTION')}
                  </Button>
                  <Button
                    className={classes.button}
                    size="small"
                    href={Routes.organisationHomePath(organisation)}
                    gutterBottom
                  >
                    {t('KNOW_MORE')}
                  </Button>
                </Box>
              )}
              {!isOnline && (
                <Box height="100%" display="flex" alignItems="flex-end" flexDirection="row-reverse">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    href="/una-national/una-administratif/quotation"
                    gutterBottom
                  >
                    {t('APP.ORGANISATION.HOME.SERVICES.ACTION')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  render() {
    return this.renderOrganisation();
  }
}
export default withStyles(styles)(withTranslation()(CardOrganisation));
