import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import _ from 'lodash';
import { grey } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import Images from '../../../Themes/Images';

const styles = theme => ({
  card: {
    height: '170px',
  },
  cardAction: {
    display: 'flex',
  },
  media: {
    objectFit: 'cover',
    width: '100px',
    minWidth: '170px',
    height: '170px',
    flex: '1 0 auto',
  },
  mediaRoot: {
    // backgroundColor: 'black',
  },
  header: {
    width: '70%',
  },
  headerContent: {
    paddingTop: '5px',
    paddingBottom: '0px',
  },
  headerTypo: {
    // height: '45px',
    fontWeight: 600,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  chips: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  chip: {
    height: 20,
  },
  excerptContent: {
    paddingTop: '0px',
    paddingBottom: '10px',
  },
  excerptTypo: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  dateContent: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  dateTypo: {
    color: grey[500],
  },
  organisationPreviewContent: {
    paddingTop: '0px',
    paddingBottom: '10px !important',
    lineHeight: 1.2,
  },
});

type Props = {
  task: Object,
  organisationPreview: Boolean,
  readOnly: Boolean,
  classes: Object,
};

class CardTask extends React.PureComponent<Props> {
  renderCard = () => {
    const { classes, task, organisationPreview, readOnly } = this.props;
    const fallbackCoverUrl = Images.logo.wello_circle;
    const taskCoverUrl = _.get(task, 'cover_url');
    const eventEndDate = new Date(task.start_date);
    eventEndDate.setHours(eventEndDate.getHours() + 2);
    const orgaName = `${task?.holding?.name} - ${task?.organisation_name}`;

    return (
      <>
        <CardMedia
          component="img"
          classes={{
            media: classes.media,
          }}
          image={taskCoverUrl || fallbackCoverUrl}
          title={task.excerpt}
        />
        <div className={classes.header}>
          {!readOnly && (
            <CardHeader
              className={classes.headerContent}
              title={
                <Typography variant="h6" component="h3" className={classes.headerTypo}>
                  {task.description}
                </Typography>
              }
              subheader={
                <Typography variant="subtitle2" component="div" className={classes.dateTypo}>
                  {`${moment(task?.twig_for_date?.event_date).format('dddd LL')} ${
                    task?.twig_for_date?.event_start_time
                  }`}
                </Typography>
              }
            />
          )}
          {readOnly && (
            <CardHeader
              className={classes.headerContent}
              title={
                <Typography variant="h6" component="h3" className={classes.headerTypo}>
                  {task.description}
                </Typography>
              }
            />
          )}
          {task.expertise && (
            <CardContent className={classes.chips}>
              <Chip
                className={classes.chip}
                style={{ backgroundColor: '#ccc', color: 'white' }}
                size="small"
                label={task.expertise?.label}
              />
            </CardContent>
          )}
          <CardContent className={classes.excerptContent}>
            <Typography variant="body2" className={classes.excerptTypo}>
              {task.excerpt}
            </Typography>
          </CardContent>
          {organisationPreview && (
            <CardContent className={classes.organisationPreviewContent}>
              <Typography
                variant="subtitle2"
                component="div"
                className={classes.organisationPreviewContent}
              >
                Organisée par {orgaName}
              </Typography>
            </CardContent>
          )}
        </div>
      </>
    );
  };

  render() {
    const { classes, task, readOnly } = this.props;
    const taskCoverUrl = _.get(task, 'cover_url');
    const eventStartDate = new Date(task?.start_date);
    const eventEndDate = new Date(task?.start_date);
    return (
      <>
        <div itemScope itemProp="event" itemType="http://schema.org/Event">
          <meta itemProp="name" content={task?.description} />
          <meta
            itemProp="description"
            content={
              task?.excerpt && Boolean(task?.excerpt?.trim()) ? task?.excerpt : task?.description
            }
          />
          <meta itemProp="image" content={taskCoverUrl} />
          <meta
            itemProp="url"
            content={`/${task.holding_slug}/${task.organisation_slug}/activites/${task.slug}/date/${task.start_date}`}
          />
          <div itemProp="location" itemScope itemType="http://schema.org/Place">
            <meta itemProp="name" content={task.formatted_address} />
            <meta itemProp="address" content={task.formatted_address} />
            <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
              <meta itemProp="latitude" content={task.coordinates?.latitude} />
              <meta itemProp="longitude" content={task.coordinates?.longitude} />
            </div>
          </div>
          <meta itemProp="startDate" content={eventStartDate.toISOString()} />
          <meta itemProp="endDate" content={eventEndDate.toISOString()} />
          <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <meta itemProp="price" content="0" />
            <meta itemProp="priceCurrency" content="EUR" />
            <meta itemProp="availability" content="http://schema.org/LimitedAvailability" />
            <meta
              itemProp="url"
              content={`/${task.holding_slug}/${task.organisation_slug}/activites/${task.slug}/date/${task.start_date}`}
            />
            <meta itemProp="validFrom" content={eventStartDate.toISOString()} />
          </div>
          <div itemProp="performer" itemScope itemType="http://schema.org/Organization">
            <meta itemProp="name" content={task.organisation_name} />
          </div>
          <div itemProp="organizer" itemScope itemType="http://schema.org/Organization">
            <meta itemProp="name" content={task.organisation_name} />
            <meta itemProp="url" content="" />
          </div>
          <meta itemProp="typicalAgeRange" content="60+" />
          <meta itemProp="eventStatus" content="https://schema.org/EventScheduled" />
          <meta
            itemProp="eventAttendanceMode"
            content="https://schema.org/MixedEventAttendanceMode"
          />
        </div>
        <Card className={classes.card}>
          {readOnly && (
            <CardActionArea className={classes.cardAction}>{this.renderCard()} </CardActionArea>
          )}
          {!readOnly && (
            <CardActionArea
              component={Link}
              to={`/${task.holding_slug}/${task.organisation_slug}/activites/${task.slug}/date/${task.start_date}`}
              className={classes.cardAction}
            >
              {this.renderCard()}
            </CardActionArea>
          )}
        </Card>
      </>
    );
  }
}

export default withStyles(styles)(CardTask);
