import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  card: {
    margin: '2em',
  },
  cardLogo: {
    width: '200px',
    height: '90px',
    marginBottom: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  icon: {
    marginTop: '30px',
    marginBottom: '30px',
    backgroundColor: '#fff',
    '&>*': {
      width: '40px',
      height: '40px',
      color: theme.current.primary.main,
    },
  },
  button: {
    marginTop: '20px',
    marginBottom: '10px',
  },
});

type Props = {
  classes: Object,
  icon: Object,
  title: String,
  resume: String,
  link: String,
};

function ServiceCard({ icon, title, resume, link, classes }: Props) {
  const { t } = useTranslation();
  return (
    <Card className={classes.card} elevation={0}>
      <CardContent align="center">
        <Avatar color="primary" href={link} className={classes.icon}>
          {icon}
        </Avatar>
        <Typography variant="h5" component="h3" gutterBottom>
          {t(title)}
        </Typography>
        <Typography variant="body2">{t(resume)}</Typography>
        <Button color="inherit" href={link} className={classes.button}>
          {t('KNOW_MORE')}
        </Button>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(ServiceCard);
