/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable global-require */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { isClientSide } from '../../Config/ServerConfig';
import { Images } from '../../Themes';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  undefinedPolygon: {
    opacity: 0.4,
  },
  definedPolygon: {
    opacity: 1,
  },
  definedBtn: {
    height: '100%',
    width: '100%',
    zIndex: 1000,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetBtn: {
    zIndex: 1000,
    position: 'absolute',
    bottom: '8px',
    right: '8px',
  },
}));

type Props = {
  height: Number,
  coordinates: Object,
  points: Array,
  setPoints: Function,
  cities: Array,
};

const MapWithPolygon = ({ coordinates, height, points, setPoints, cities }: Props) => {
  const latitude = coordinates?.latitude || null;
  const longitude = coordinates?.longitude || null;
  const { t } = useTranslation();
  const classes = useStyles();

  if (isClientSide() && latitude && longitude) {
    const L = require('react-leaflet');
    const Leaflet = require('leaflet');

    const undefinedPolygon = points?.length < 1;
    const editMode = Boolean(setPoints);
    const [tempPoints, setTempPoints] = React.useState(points);
    const edited = React.useRef(false);
    const [center, setCenter] = React.useState([latitude, longitude]);

    const getOctogon = (lat, lng) => {
      const pts = [];
      pts.push([lat, lng + 0.04]);
      pts.push([lat - 0.02, lng + 0.03]);
      pts.push([lat - 0.03, lng]);
      pts.push([lat - 0.02, lng - 0.03]);
      pts.push([lat, lng - 0.04]);
      pts.push([lat + 0.02, lng - 0.03]);
      pts.push([lat + 0.03, lng]);
      pts.push([lat + 0.02, lng + 0.03]);
      return pts;
    };

    const handleDefinePolygon = () => {
      let pts = tempPoints?.slice();
      if (!pts || pts.length === 0) {
        // initialize 3 first points
        pts = getOctogon(latitude, longitude);
        setPoints(pts);
        setTempPoints(pts);
      }
    };

    const movablePoints = React.useMemo(() => {
      if (!editMode || undefinedPolygon) return null;

      const pts = tempPoints?.slice();
      const movables = [];

      // for each edge, add middle point as movable point
      pts.forEach((p, i) => {
        movables.push(p);

        if (i < pts.length - 1) {
          movables.push([(pts[i][0] + pts[i + 1][0]) / 2, (pts[i][1] + pts[i + 1][1]) / 2]);
        } else {
          movables.push([(pts[i][0] + pts[0][0]) / 2, (pts[i][1] + pts[0][1]) / 2]);
        }
      });

      return movables;
    }, [points]);

    const getNewPolygonPoints = (index, newCoordinates) => {
      if (!tempPoints) return null;

      const newPoints = tempPoints.slice();
      // newPoints[index] = [newCoordinates.lat, newCoordinates.lng];
      const oldPoint = movablePoints[index];
      const ptIndex = newPoints.indexOf(oldPoint);
      if (ptIndex > -1) newPoints[ptIndex] = [newCoordinates.lat, newCoordinates.lng];
      else {
        const previousPoint = movablePoints[index - 1];
        const previousPtIndex = newPoints.indexOf(previousPoint);
        if (previousPtIndex > -1)
          newPoints.splice(previousPtIndex + 1, 0, [newCoordinates.lat, newCoordinates.lng]);
      }
      return newPoints;
    };

    const handlePointDragging = (index, newCoordinates) => {
      const newPoints = getNewPolygonPoints(index, newCoordinates);
      setTempPoints(newPoints);
    };

    const handlePointDraggEnd = (index, newCoordinates) => {
      const newPoints = getNewPolygonPoints(index, newCoordinates);
      setTempPoints(newPoints);
      setPoints(newPoints);
    };

    const handleCenterDragging = newCoordinates => {
      if (!tempPoints) return;

      const offsetLat = newCoordinates.lat - center[0];
      const offsetLng = newCoordinates.lng - center[1];

      const newPoints = tempPoints.slice().map(pt => [pt[0] + offsetLat, pt[1] + offsetLng]);
      setTempPoints(newPoints);
      setCenter([newCoordinates.lat, newCoordinates.lng]);
    };

    const handleCenterDraggEnd = newCoordinates => {
      if (!tempPoints) return;

      const offsetLat = newCoordinates.lat - center[0];
      const offsetLng = newCoordinates.lng - center[1];

      const newPoints = tempPoints.slice().map(pt => [pt[0] + offsetLat, pt[1] + offsetLng]);
      setTempPoints(newPoints);
      setPoints(newPoints);
      setCenter([newCoordinates.lat, newCoordinates.lng]);
    };

    const handlePointDblClick = (index, ptCoordinates) => {
      if (!tempPoints || tempPoints.length < 4) return; // can not have less than 3 points

      const movPt = movablePoints[index];
      const ptIndex = tempPoints.indexOf(movPt);
      if (ptIndex < 0) return; // can not delete middle point

      const newPoints = tempPoints
        .slice()
        .filter(pt => pt[0] !== ptCoordinates.lat || pt[1] !== ptCoordinates.lng);
      setTempPoints(newPoints);
      setPoints(newPoints);
    };

    const handleReset = () => {
      setCenter([latitude, longitude]);
      setTempPoints([]);
      setPoints([]);
    };

    const dotIcon = Leaflet.icon({
      iconUrl: `${Images.wello.marker_icon_dot}`,
      iconSize: [11, 11],
    });

    const transDotIcon = Leaflet.icon({
      iconUrl: `${Images.wello.marker_icon_transp_dot}`,
      iconSize: [11, 11],
    });

    const PointsComponent = React.useMemo(
      () =>
        movablePoints?.map((pt, i) => (
          <L.Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`movable-${i}`}
            draggable
            position={pt}
            icon={points.includes(pt) ? dotIcon : transDotIcon}
            eventHandlers={{
              drag: event => handlePointDragging(i, event.target.getLatLng()),
              dragend: event => handlePointDraggEnd(i, event.target.getLatLng()),
              dblclick: event => handlePointDblClick(i, event.target.getLatLng()),
            }}
          />
        )),
      [movablePoints],
    );

    const zoneToPolygon = city => {
      let polygons = city.zone
        .replace(/POLYGON \(\(/, '')
        .replace(/\)\)$/, '')
        .split(', ');
      polygons = polygons?.map(polygon => {
        const c = polygon.split(' ');
        return [c[1], c[0]]?.map(latLng => parseFloat(latLng, 10));
      });
      return polygons;
    };

    return (
      <Grid className={classes.container}>
        {editMode && undefinedPolygon && (
          <Grid className={classes.definedBtn}>
            <Button variant="contained" color="primary" onClick={handleDefinePolygon}>
              {t('Commencer')}
            </Button>
          </Grid>
        )}
        <Grid
          className={
            editMode && undefinedPolygon ? classes.undefinedPolygon : classes.definedPolygon
          }
        >
          <L.MapContainer
            center={[latitude, longitude]}
            zoom={points ? 12 : 8}
            scrollWheelZoom={false}
            style={{ height: `${height || 400}px` }}
          >
            <L.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {/* <L.Marker position={position} /> */}

            {editMode && tempPoints && (
              <L.Polygon key="pol2" positions={tempPoints} color="grey" weight={2} />
            )}
            {points && <L.Polygon key="pol" positions={points} color="blue" />}
            {editMode && PointsComponent}
            {editMode &&
              (cities || []).map(city => (
                <L.Polygon key={city.id} positions={zoneToPolygon(city)} color="red" />
              ))}
            {!edited.current && (
              <L.Marker
                // eslint-disable-next-line react/no-array-index-key
                draggable={editMode}
                position={center}
                eventHandlers={{
                  drag: event => handleCenterDragging(event.target.getLatLng()),
                  dragend: event => handleCenterDraggEnd(event.target.getLatLng()),
                }}
              />
            )}
            {editMode && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.resetBtn}
                onClick={handleReset}
              >
                {t('APP.ORGANISATION.SETTINGS.GENERAL.MAP.RESET')}
              </Button>
            )}
          </L.MapContainer>
        </Grid>
      </Grid>
    );
  }
  return <></>;
};

if (isClientSide()) {
  // Solve leafleat marker issue : https://github.com/PaulLeCam/react-leaflet/issues/453
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable global-require */
  const L = require('leaflet');
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  /* eslint-enable global-require */
  /* eslint-enable no-underscore-dangle */
}

export default MapWithPolygon;
